import { requireFun } from '@/utils'
import { isMoney } from '@/utils/validate'
export const formFile = [
  {
    label: '支付订单号',
    prop: 'paymentOrderCode',
    name: 'paymentOrderCode',
    colNum: 8,
    placeholder: '',
    slotName: 'paymentOrderCodeSlot',
    disabled: true
  },
  {
    label: '订单数',
    prop: 'orderCount',
    name: 'orderCount',
    colNum: 8,
    placeholder: '',
    disabled: true
  },
  {
    label: '款式总计',
    prop: 'structAmount',
    name: 'structAmount',
    colNum: 8,
    placeholder: '',
    disabled: true,
    slotName: 'structAmountSlot'
  },
  {
    label: '货款总计',
    prop: 'productAmount',
    name: 'productAmount',
    colNum: 8,
    placeholder: '',
    slotName: 'huokuanSlot',
    disabled: true
  },
  {
    label: '运费总计',
    prop: 'freightAmount',
    name: 'freightAmount',
    colNum: 8,
    placeholder: '',
    slotName: 'freightAmountSlot'
  },
  {
    label: '用户',
    prop: 'nickName',
    name: 'user',
    colNum: 8,
    placeholder: '',
    disabled: true
  },
  {
    label: '应付总额',
    prop: 'totalAmount',
    name: 'totalAmount',
    colNum: 8,
    placeholder: '',
    slotName: 'totalAmountSlot'
  },
  {
    label: '内部优惠金额',
    prop: 'couponAmount',
    name: 'couponAmount',
    colNum: 8,
    placeholder: '',
    rule: [
      {
        required: true,
        message: '优惠金额必填，最少为0'
      },
      {
        validator: isMoney
      }
    ],
    slotName: 'couponAmountSlot'
  },
  {
    label: '实际应付总额',
    prop: 'realAmount',
    name: 'realAmount',
    colNum: 8,
    placeholder: '',
    slotName: 'realAmountSlot'
  }
]
