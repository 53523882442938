var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editBtnFormPart"},[_c('h3',{staticClass:"formTitle"},[_vm._v("支付订单信息")]),_c('BaseForm',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"form",attrs:{"label-width":"120px","cols":_vm.formFile,"form":_vm.editForm},scopedSlots:_vm._u([{key:"paymentOrderCodeSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('div',{staticClass:"payOrderCodeBoxClass"},[_c('el-input',{staticClass:"payOrderCodeInputClass",attrs:{"disabled":""},model:{value:(_vm.editForm[prop]),callback:function ($$v) {_vm.$set(_vm.editForm, prop, $$v)},expression:"editForm[prop]"}}),_c('p',{staticClass:"payOrderCodePClass"},[_vm._v(_vm._s(_vm.editForm[prop]))])],1)]}},{key:"structAmountSlot",fn:function(){return [_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.structAmountStr),callback:function ($$v) {_vm.structAmountStr=$$v},expression:"structAmountStr"}})]},proxy:true},{key:"huokuanSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.editForm[prop]),callback:function ($$v) {_vm.$set(_vm.editForm, prop, $$v)},expression:"editForm[prop]"}},[_c('template',{slot:"append"},[_vm._v(" 元 ")])],2)]}},{key:"freightAmountSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.editForm[prop]),callback:function ($$v) {_vm.$set(_vm.editForm, prop, $$v)},expression:"editForm[prop]"}},[_c('template',{slot:"append"},[_vm._v(" 元 ")])],2)]}},{key:"totalAmountSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.editForm[prop]),callback:function ($$v) {_vm.$set(_vm.editForm, prop, $$v)},expression:"editForm[prop]"}},[_c('template',{slot:"append"},[_vm._v(" 元 ")])],2)]}},{key:"couponAmountSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('el-input',{attrs:{"disabled":!!_vm.isLocked},on:{"change":_vm.couponAmountChange},model:{value:(_vm.editForm[prop]),callback:function ($$v) {_vm.$set(_vm.editForm, prop, $$v)},expression:"editForm[prop]"}},[_c('template',{slot:"append"},[_vm._v(" 元 ")])],2),_c('div',{staticClass:"tipMaxCouponAmount"},[_vm._v("提示: 最大优惠价格为"+_vm._s(_vm.editForm.maxCouponAmount))])]}},{key:"realAmountSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.editForm[prop]),callback:function ($$v) {_vm.$set(_vm.editForm, prop, $$v)},expression:"editForm[prop]"}},[_c('template',{slot:"append"},[_vm._v(" 元 ")])],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }