import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

export function list(params) {
  return request({
    url: '/api/prim_prod/prim_show_img/',
    method: 'get',
    params
  })
}

export function add(data) {
  return request({
    url: '/externaladmin/productService/styleShowImageGroup/createItemAndMask',
    method: 'post',
    data
  })
}

export function change(data) {
  return request({
    url: '/externaladmin/productService/styleShowImageGroupItemMask/create',
    method: 'post',
    data
  })
}

export function read(data) {
  return request({
    url: `/api/prim_prod/prim_show_img/${data}/`,
    method: 'get',
    data
  })
}

export function edit(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/styleShowImageGroupItem/update',
    method: 'post',
    data
  })
}

export function del(id) {
  const idList = [id]
  return request({
    url: '/externaladmin/productService/styleShowImageGroupItem/delete',
    method: 'post',
    data: {
      idList
    }
  })
}
