<template>
  <div class="editBtnFormPart">
    <h3 class="formTitle">支付订单信息</h3>
    <BaseForm ref="form" label-width="120px" :cols="formFile" :form="editForm" v-loading="loading">
      <!-- structAmountSlot -->
      <template #paymentOrderCodeSlot="{ scoped: { prop } }">
        <div class="payOrderCodeBoxClass">
          <el-input disabled v-model="editForm[prop]" class="payOrderCodeInputClass"> </el-input>
          <p class="payOrderCodePClass">{{ editForm[prop] }}</p>
        </div>
      </template>
      <template #structAmountSlot>
        <el-input disabled v-model="structAmountStr"> </el-input>
      </template>
      <template #huokuanSlot="{ scoped: { prop } }">
        <el-input disabled v-model="editForm[prop]">
          <template slot="append"> 元 </template>
        </el-input>
      </template>
      <template #freightAmountSlot="{ scoped: { prop } }">
        <el-input disabled v-model="editForm[prop]">
          <template slot="append"> 元 </template>
        </el-input>
      </template>
      <template #totalAmountSlot="{ scoped: { prop } }">
        <el-input v-model="editForm[prop]" disabled>
          <template slot="append"> 元 </template>
        </el-input>
      </template>
      <template #couponAmountSlot="{ scoped: { prop } }">
        <el-input v-model="editForm[prop]" :disabled="!!isLocked" @change="couponAmountChange">
          <template slot="append"> 元 </template>
        </el-input>
        <div class="tipMaxCouponAmount">提示: 最大优惠价格为{{ editForm.maxCouponAmount }}</div>
      </template>
      <template #realAmountSlot="{ scoped: { prop } }">
        <el-input disabled v-model="editForm[prop]">
          <template slot="append"> 元 </template>
        </el-input>
      </template>
    </BaseForm>
  </div>
</template>

<script>
import { formFile } from './formFile'
import { numMaxConstruct } from '@/utils/validate'
import { getPaymentOrderList } from '@/api/orderReviewApi'
import { deepClone } from '@/components/avue/utils/util'
export default {
  components: {},
  props: {
    paymentOrderId: {
      type: [Number, String]
    },
    structAmountStr: {
      type: String,
      default: ' '
    },
    isLocked: {
      type: Number
    }
  },
  data() {
    return {
      editForm: {},
      formFile,
      loading: false
    }
  },
  watch: {
    // 监听内部优惠金额的变化
    'editForm.couponAmount': {
      handler(newVal) {
        this.editForm.realAmount = Number((this.editForm.totalAmount - newVal).toFixed(2))
      }
    }
  },
  methods: {
    async getPaymentOrderListByIdList() {
      this.loading = true
      const { detail } = await getPaymentOrderList({ id: this.paymentOrderId })
      this.editForm = detail
      const info = {
        productAmount: this.editForm.productAmount,
        remark: this.editForm.remark,
        maxCouponAmount: this.editForm.maxCouponAmount
      }
      this.$emit('takePaymentInfo', info)
      this.loading = false
    },
    couponAmountChange() {
      if (!this.editForm.couponAmount) {
        this.editForm.couponAmount = 0
      }
      const val = {
        couponAmount: this.editForm.couponAmount
      }
      this.$emit('payCouponAmountChange', val)
    },
    addRule() {
      this.formFile.map((item) => {
        if (item.prop === 'couponAmount') {
          if (item.rule?.length === 2) {
            item.rule.push({
              validator: numMaxConstruct(
                this.editForm.maxCouponAmount,
                `优惠金额必须小于${this.editForm.maxCouponAmount}`
              )
            })
          } else {
            item.rule[2] = {
              validator: numMaxConstruct(
                this.editForm.maxCouponAmount,
                `优惠金额必须小于${this.editForm.maxCouponAmount}`
              )
            }
          }
        }
      })
    }
  },
  async created() {
    await this.getPaymentOrderListByIdList()
    console.log('this.editForm.maxCouponAmount', this.editForm.maxCouponAmount)
    this.addRule()
    await this.couponAmountChange()
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-input__inner {
  border-radius: 2px;
}
::v-deep .el-input-group__append {
  border-radius: 0;
  padding: 0 5px;
}

.editBtnFormPart {
  height: 30%;
  border: 1px solid #ccc;
  .formTitle {
    margin: 20px 0 0 20px;
  }
  .payOrderCodeBoxClass {
    position: relative;
    .payOrderCodePClass {
      position: absolute;
      margin: 0;
      padding: 0px 10px;
      top: -45px;
      left: 0;
      opacity: 0;
      border: 1px solid #ccc;
      border-radius: 5px;
      color: #606266;
      box-shadow: 0px 0px 5px 1px rgb(221, 221, 221);
      transition: all 0.5s;
      cursor: default;
      user-select: none;
      z-index: 1000;
    }
    .el-input.payOrderCodeInputClass:hover + .payOrderCodePClass {
      opacity: 1;
    }
  }
  .tipMaxCouponAmount {
    font-size: 12px;
    color: #999;
  }
}
</style>
