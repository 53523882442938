import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'
// 获取支付订单列表信息
export function getPaymentOrderList(data = {}) {
  return request({
    url: '/externaladmin/financeService/paymentOrder/getPaymentOrderInfo',
    method: 'post',
    data
  })
}
export function updateCouponAmount(data = {}) {
  return request({
    url: '/externaladmin/financeService/paymentOrder/updateCouponAmount',
    method: 'post',
    data
  })
}
