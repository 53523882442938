<template>
  <div class="editBtn app-container">
    <editBtnFormPart
      :tableData="tableData"
      :paymentOrderId="paymentProductId"
      :structAmountStr="structAmountStr"
      @payCouponAmountChange="payCouponAmountChange"
      @takePaymentInfo="takePaymentInfo"
      :isLocked="Number($route.query.isLocked)"
    ></editBtnFormPart>
    <editBtnProductTablePart
      :paymentOrderId="paymentProductId"
      @structAmount="takeStructAmount"
    ></editBtnProductTablePart>
    <editBtnOrderTablePart
      :paymentOrderId="paymentProductId"
      :couponAmountMonet="form.couponAmount"
      :productAmount="productAmount"
      @getUpdateItemCouponAmountList="getUpdateItemCouponAmountList"
    ></editBtnOrderTablePart>
    备注:
    <el-input
      style="width: 300px; margin-top: 15px"
      size="mini"
      v-model="form.remark"
      placeholder="单行输入"
    ></el-input>
    <div class="btn-box">
      <el-button
        type="primary"
        @click="updateCouponAmount"
        :loading="loading"
        v-if="checkPermission(['externaladmin:financeService:orderReview:save']) && !Number($route.query.isLocked)"
        >保存</el-button
      >
      <el-button @click="$router.back()">取消</el-button>
    </div>
  </div>
</template>

<script>
import editBtnFormPart from './editBtnFormPart.vue'
import editBtnOrderTablePart from './editBtnOrderTablePart.vue'
import editBtnProductTablePart from './editBtnProductTablePart.vue'
import { updateCouponAmount } from '@/api/orderReviewApi'
export default {
  components: {
    editBtnFormPart,
    editBtnOrderTablePart,
    editBtnProductTablePart
  },
  props: {},
  data() {
    return {
      tableData: [],
      paymentProductId: this.$route.query.paymentOrderId,
      form: {
        remark: '',
        updateItemCouponAmountList: [],
        couponAmount: '',
        id: this.$route.query.paymentOrderId
      },
      loading: false,
      // 款式总计
      structAmountStr: '',
      // 货款总计
      productAmount: 0,
      // 最大优惠金额
      maxCouponAmount: 0
    }
  },
  computed: {},
  methods: {
    // 拿到新优惠数据数组
    payCouponAmountChange(val) {
      this.form.couponAmount = val.couponAmount
    },
    // 拿到跟新的产品相关数据列表
    getUpdateItemCouponAmountList(val) {
      this.form.updateItemCouponAmountList = val
    },
    //点击确定按钮
    async updateCouponAmount() {
      try {
        if (this.form.couponAmount > this.maxCouponAmount) {
          this.$message.warning(`最大优惠不能超过${this.maxCouponAmount}元`)
          return
        }
        console.log(this.form)
        if (!Number(this.form.couponAmount) && Number(this.form.couponAmount) !== 0) {
          this.$message.warning(`请输入正确的金额格式`)
          return
        }
        this.loading = true
        if (!this.form.couponAmount) {
          this.form.couponAmount = 0
        }
        const { code } = await updateCouponAmount(this.form)
        if (code === 0) {
          this.$message.success('操作成功')
          this.$router.back()
        } else {
          this.$message.error('操作失败')
        }
        this.loading = false
      } catch {
        this.loading = false
      }
    },

    // 拿到款式总计
    takeStructAmount(val) {
      this.structAmountStr = val
    },
    // 拿到支付订单的信息
    takePaymentInfo(val) {
      this.form.remark = val.remark
      this.productAmount = val.productAmount
      this.maxCouponAmount = val.maxCouponAmount
    }
  }
}
</script>

<style scoped lang="scss">
.editBtn {
  ::v-deep .el-dialog__header {
    text-align: left;
    border-bottom: 1px solid #ccc;
  }
  .btn-box {
    text-align: center;
  }
}
</style>
