var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editBtnTablePart"},[_c('h3',{staticClass:"tableTitle"},[_vm._v("订单详情")]),_c('CommonTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],key:"1000",staticStyle:{"margin-top":"15px"},attrs:{"height":"auto","selection":false,"cols":_vm.orderTableCols,"infoData":_vm.afterData,"cellClassName":_vm.setCellClassName},scopedSlots:_vm._u([{key:"totalPriceSlot",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.totalPrice(row))+" ")]}},{key:"productCustomSkuCodeSlot",fn:function(ref){
var orderItemDTOList = ref.row.orderItemDTOList;
return [_vm._l((orderItemDTOList),function(ref){
var id = ref.id;
var productCustomSkuCode = ref.productCustomSkuCode;
return [_c('div',{key:id},[_vm._v(_vm._s(productCustomSkuCode || '暂无'))])]})]}},{key:"productStructNameSlot",fn:function(ref){
var orderItemDTOList = ref.row.orderItemDTOList;
return [_vm._l((orderItemDTOList),function(ref){
var id = ref.id;
var productStructName = ref.productStructName;
return [_c('div',{key:id},[_vm._v(_vm._s(productStructName))])]})]}},{key:"productSizeNameSlot",fn:function(ref){
var orderItemDTOList = ref.row.orderItemDTOList;
return [_vm._l((orderItemDTOList),function(ref){
var id = ref.id;
var productSizeName = ref.productSizeName;
return [_c('div',{key:id},[_vm._v(_vm._s(productSizeName))])]})]}},{key:"lowerLimitPriceSlot",fn:function(ref){
var orderItemDTOList = ref.row.orderItemDTOList;
return [_vm._l((orderItemDTOList),function(ref){
var id = ref.id;
var lowerLimitPrice = ref.lowerLimitPrice;
return [_c('div',{key:id},[_vm._v(" "+_vm._s(lowerLimitPrice || '暂无数据')+" ")])]})]}},{key:"internalUnitPriceSlot",fn:function(ref){
var orderItemDTOList = ref.row.orderItemDTOList;
return [_vm._l((orderItemDTOList),function(ref){
var id = ref.id;
var internalUnitPrice = ref.internalUnitPrice;
return [_c('div',{key:id},[_vm._v(_vm._s(internalUnitPrice))])]})]}},{key:"realUnitPriceSlot",fn:function(ref){
var orderItemDTOList = ref.row.orderItemDTOList;
return [_vm._l((orderItemDTOList),function(ref){
var id = ref.id;
var internalTotalPrice = ref.internalTotalPrice;
var productCount = ref.productCount;
var couponAmount = ref.couponAmount;
return [_c('div',{key:id},_vm._l((_vm.realUnitPriceChange(internalTotalPrice, productCount, couponAmount)),function(item,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(("￥" + (item.price) + "*" + (item.number)))+" ")])}),0)]})]}},{key:"productCountSlot",fn:function(ref){
var orderItemDTOList = ref.row.orderItemDTOList;
return [_vm._l((orderItemDTOList),function(ref){
var id = ref.id;
var productCount = ref.productCount;
return [_c('div',{key:id},[_vm._v(_vm._s(productCount || '暂无数据'))])]})]}},{key:"couponAmountSunSlot",fn:function(ref){
var row = ref.row;
return [_vm._l((row.orderItemDTOList),function(item){return [_c('div',{key:item.id,staticClass:"editClass"},[_vm._v(" "+_vm._s(("￥" + (item.couponAmount)))+" ")])]})]}},{key:"expressCompanySlot",fn:function(ref){
var row = ref.scoped.row;
return [_vm._v(" "+_vm._s(_vm.expressCompany(row))+" ")]}},{key:"SKUTotalPriceSlot",fn:function(ref){
var orderItemDTOList = ref.row.orderItemDTOList;
return _vm._l((orderItemDTOList),function(ref){
var id = ref.id;
var internalTotalPrice = ref.internalTotalPrice;
var couponAmount = ref.couponAmount;
return _c('div',{key:id},[_vm._v(" "+_vm._s(("￥" + (_vm.SKUTotalPrice(internalTotalPrice, couponAmount))))+" ")])})}},{key:"templateFreightSlot",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(("￥" + (_vm.getPaymentFreight(row))))+" ")])]}},{key:"couponAmountSlot",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(("￥" + (_vm.orderCouponAmountMonet(row))))+" ")]}}])}),_c('el-pagination',{attrs:{"layout":"total, sizes, prev, pager, next, jumper","background":"","page-sizes":[10, 20, 30, 40],"page-size":_vm.pageSize,"current-page":_vm.currentPage,"total":_vm.total},on:{"current-change":_vm.handleCurrentChange,"size-change":_vm.handleSizeChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }