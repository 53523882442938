<template>
  <div class="editBtnTablePart">
    <h3 class="tableTitle">订单详情</h3>
    <CommonTable
      height="auto"
      :selection="false"
      :cols="orderTableCols"
      v-loading="tableLoading"
      :infoData="afterData"
      key="1000"
      style="margin-top: 15px"
      :cellClassName="setCellClassName"
    >
      <!-- totalPrice -- 订单总额 -- 优惠后的订单总额-->
      <template #totalPriceSlot="{ row }"> {{ totalPrice(row) }} </template>
      <!-- productCustomSkuCode 自定义SKU-->
      <template #productCustomSkuCodeSlot="{ row: { orderItemDTOList } }">
        <template v-for="{ id, productCustomSkuCode } in orderItemDTOList">
          <div :key="id">{{ productCustomSkuCode || '暂无' }}</div>
        </template>
      </template>
      <!-- 款式 -->
      <template #productStructNameSlot="{ row: { orderItemDTOList } }">
        <template v-for="{ id, productStructName } in orderItemDTOList">
          <div :key="id">{{ productStructName }}</div>
        </template>
      </template>
      <!-- 尺码 -->
      <template #productSizeNameSlot="{ row: { orderItemDTOList } }">
        <template v-for="{ id, productSizeName } in orderItemDTOList">
          <div :key="id">{{ productSizeName }}</div>
        </template>
      </template>
      <!-- lowerLimitPrice 最低售价 -->
      <template #lowerLimitPriceSlot="{ row: { orderItemDTOList } }">
        <template v-for="{ id, lowerLimitPrice } in orderItemDTOList">
          <div :key="id">
            {{ lowerLimitPrice || '暂无数据' }}
          </div>
        </template>
      </template>
      <!-- internalUnitPriceSlot 单价 -->
      <template #internalUnitPriceSlot="{ row: { orderItemDTOList } }">
        <template v-for="{ id, internalUnitPrice } in orderItemDTOList">
          <div :key="id">{{ internalUnitPrice }}</div>
        </template>
      </template>
      <!-- realUnitPriceSlot 实际单价 -->
      <template #realUnitPriceSlot="{ row: { orderItemDTOList } }">
        <template v-for="{ id, internalTotalPrice, productCount, couponAmount } in orderItemDTOList">
          <div :key="id">
            <div
              v-for="(item, index) in realUnitPriceChange(internalTotalPrice, productCount, couponAmount)"
              :key="index"
            >
              {{ `￥${item.price}*${item.number}` }}
            </div>
          </div>
        </template>
      </template>
      <!-- productCount 数量-->
      <template #productCountSlot="{ row: { orderItemDTOList } }">
        <template v-for="{ id, productCount } in orderItemDTOList">
          <div :key="id">{{ productCount || '暂无数据' }}</div>
        </template>
      </template>
      <!-- couponAmountSun 单品优惠 -->
      <template #couponAmountSunSlot="{ row }">
        <template v-for="item in row.orderItemDTOList">
          <div :key="item.id" class="editClass">
            {{ `￥${item.couponAmount}` }}
          </div>
        </template>
      </template>
      <!-- 物流公司 -->
      <template #expressCompanySlot="{ scoped: { row } }">
        {{ expressCompany(row) }}
      </template>
      <!-- SKU总价 (显示优惠后的价格)-->
      <template #SKUTotalPriceSlot="{ row: { orderItemDTOList } }">
        <div v-for="{ id, internalTotalPrice, couponAmount } in orderItemDTOList" :key="id">
          {{ `￥${SKUTotalPrice(internalTotalPrice, couponAmount)}` }}
        </div>
      </template>
      <!-- 运费-->
      <template #templateFreightSlot="{ row }">
        <span>
          {{ `￥${getPaymentFreight(row)}` }}
        </span>
      </template>
      <template #couponAmountSlot="{ row }">
        {{ `￥${orderCouponAmountMonet(row)}` }}
      </template>
    </CommonTable>

    <el-pagination
      layout="total, sizes, prev, pager, next, jumper"
      background
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { orderTableCols } from './tableCols'
import { initDataMixin } from '@/mixins'
import { deepClone } from '@/components/avue/utils/util'
import { change } from '@/api/product/primShowApi'
import { getPaymentFreight, getTotalPrice, getCouponAmount } from '@/utils'
export default {
  mixins: [initDataMixin],
  components: {},
  props: {
    paymentOrderId: {
      type: [Number, String]
    },
    // 内部优惠金额
    couponAmountMonet: {
      type: [Number, String],
      required: true,
      default: 0
    },
    // 货款总计
    productAmount: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      orderTableCols,
      lock: true,
      url: '/externaladmin/orderService/externalOrder/orderList',
      query: {
        paymentProductId: this.paymentOrderId,
        isReturnRelatedData: 1,
        isGetAllOrder: 1
      },
      size: 0, // 请求所有数据
      currentPage: 1,
      pageSize: 10,
      afterData: []
    }
  },
  computed: {
    // 订单总额 -- 优惠后
    totalPrice() {
      return (row) => {
        // 运费 加上 订单的货款
        const externalOrderFinance = row.externalOrderFinance
        if (!externalOrderFinance) return
        const paymentFreight = $GET(externalOrderFinance, 'paymentFreight', 0)
        const internalTotalPrice = $GET(externalOrderFinance, 'internalTotalPrice', 0)
        return (paymentFreight + internalTotalPrice).toFixed(2)
      }
    },
    // 订单优惠金额
    orderCouponAmountMonet() {
      return (row) => {
        console.log('orderCouponAmountMonet', row)
        //return row.couponAmount.toFixed(2)
        let orderCouponAmount = 0
        row.orderItemDTOList.map(({ couponAmount }) => {
          orderCouponAmount += Number(couponAmount)
        })
        return orderCouponAmount.toFixed(2)
      }
    },
    // sku总价 优惠后
    SKUTotalPrice() {
      return (internalTotalPrice, couponAmount) => {
        const realSkuTotalPrice = (internalTotalPrice - couponAmount).toFixed(2)
        return Number(realSkuTotalPrice)
      }
    },
    // 实际单价的计算 （sku总价（未优惠） - 优惠） / 数量
    realUnitPriceChange() {
      // 单价 internalUnitPrice, sku总价 internalTotalPrice, 数量 productCount, 单品优惠金额 couponAmount
      return (internalTotalPrice, productCount, couponAmount) => {
        // 优惠后的SKU总价
        let SKUTotalPrice = (internalTotalPrice - couponAmount).toFixed(2)
        // 优惠价格一
        let price1 = (SKUTotalPrice / productCount).toFixed(2)
        // 查看实际单价是否除得尽
        const o = Number((SKUTotalPrice % price1).toFixed(2))
        let isNaN = Number.isNaN(o)
        if (o === 0 || o - price1 === 0 || isNaN) {
          return [{ price: price1, number: productCount }]
        } else {
          // 优惠价格二
          let price1_number = productCount - 1
          let price2 = (SKUTotalPrice - (price1_number * price1).toFixed(2)).toFixed(2)
          return [
            { price: price1, number: price1_number },
            { price: price2, number: 1 }
          ]
        }
      }
    },
    // 物流公司
    expressCompany() {
      return (row) => {
        if (row.waybillType !== 2) {
          return row.expressCompanyName || '暂无'
        } else {
          return row.expressShippingMethodCnName || '暂无'
        }
      }
    }
  },
  watch: {
    couponAmountMonet(newVal) {
      const updateItemCouponAmountList = []
      // 内部优惠金额一变，所有单品优惠金额跟着变

      // 声明一个所有单品优惠金额的总和
      let couponAmountTotal = 0
      this.data.map((item_f, index_f) => {
        item_f.orderItemDTOList.map((item_s, index_s) => {
          // 算出单品的货款占总货款的比重
          let ratio = Number((item_s.productCount * item_s.internalUnitPrice) / this.productAmount)

          // 用比重与总优惠金额算出单品优惠金额
          let couponAmount = Number((ratio * newVal).toFixed(2))

          if (index_f === this.data.length - 1 && index_s === item_f.orderItemDTOList.length - 1) {
            couponAmount = Number((newVal - couponAmountTotal).toFixed(2))
          } else {
            couponAmountTotal += couponAmount
          }
          this.$set(item_s, 'couponAmount', couponAmount)
          let updateItem = {
            couponAmount: item_s.couponAmount,
            id: item_s.id,
            orderId: item_s.externalOrderId
          }
          updateItemCouponAmountList.push(updateItem)
        })
      })
      this.$emit('getUpdateItemCouponAmountList', updateItemCouponAmountList)
    }
  },
  methods: {
    getPaymentFreight,
    getCouponAmount,

    initCallBack({ detail }) {
      const updateItemCouponAmountList = []

      detail.map((item) => {
        item.orderItemDTOList.map((child) => {
          let updateItem = {
            couponAmount: child.couponAmount,
            id: child.id,
            orderId: child.externalOrderId
          }
          updateItemCouponAmountList.push(updateItem)
        })
      })

      // 页面初始数据
      let start = (this.currentPage - 1) * this.pageSize
      let end = this.currentPage * this.pageSize
      this.afterData = this.data.slice(start, end)
      this.$emit('getUpdateItemCouponAmountList', updateItemCouponAmountList)
    },
    setCellClassName({ columnIndex }) {
      if ([2, 3, 4, 5, 6, 7, 8, 10, 11].includes(columnIndex)) {
        return 'productCell'
      } else if (columnIndex === 9) {
        return 'productCellInput'
      }
      return ''
    },
    handleCurrentChange(currentPage) {
      let start = (currentPage - 1) * this.pageSize
      let end = currentPage * this.pageSize
      this.afterData = this.data.slice(start, end)
    },
    handleSizeChange(pageSize) {
      this.currentPage = 1
      let start = (this.currentPage - 1) * pageSize
      let end = this.currentPage * pageSize
      this.afterData = this.data.slice(start, end)
    }
  }
}
</script>

<style scoped lang="scss">
.editBtnTablePart {
  margin-top: 1%;
  padding: 20px;
  border: 1px solid #ccc;
  .tableTitle {
    margin: 0;
  }

  ::v-deep {
    .el-input-group__append {
      border-radius: 0;
      padding: 0 5px;
    }
    .el-input__inner {
      border-radius: 2px;
    }
    .productCell {
      padding: 0;
      .cell {
        padding: 0;
        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 60px;
          border-bottom: 1px solid #ebeef5;
        }
        :last-child {
          border-bottom: none;
        }
      }
    }
    .productCellInput {
      padding: 0;
      .cell {
        padding: 0;
        .el-input {
          padding: 10px 5px;
          border-bottom: 1px solid #ebeef5;
          &:last-child {
            border-bottom: none;
          }
        }
        .editClass {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          border-bottom: 1px solid #ebeef5;
          height: 60px;
          &:last-child {
            border-bottom: none;
          }
          .editClassIcon {
            margin-left: 5px;
            color: $--color-primary;
            cursor: pointer;
          }
        }
      }
    }
  }

  .el-pagination {
    text-align: right;
    padding: 10px 0;
    padding-right: 15px;
  }
}
</style>
