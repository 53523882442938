<template>
  <div class="editBtnProductTablePart">
    <h3 class="tableTitle">产品详情</h3>
    <CommonTable
      height="auto"
      :selection="false"
      :cols="productTableCols"
      :infoData="data"
      style="margin-top: 15px"
      v-loading="tableLoading"
    ></CommonTable>
    <PaginationBar ref="paginationBar" @refreshTableEventFun="refreshTableEventFun" :total="total"></PaginationBar>
  </div>
</template>

<script>
import { productTableCols } from './tableCols'
import { initDataMixin } from '@/mixins'
import { unique } from '@/utils'
export default {
  mixins: [initDataMixin],
  components: {},
  props: {
    paymentOrderId: {
      type: [Number, String]
    }
  },
  data() {
    return {
      productTableCols,
      productTableData: [{ orderCode: '123' }],
      url: '/externaladmin/orderService/externalOrderItem/orderItemList',
      query: {
        paymentProductId: this.paymentOrderId
      }
    }
  },
  computed: {},
  methods: {
    initCallBack({ detail } = {}) {
      const proArr = []
      detail.map(({ productStructName }) => {
        proArr.push(productStructName)
      })
      const newProArr = unique(proArr)
      let str = ''
      newProArr.map((item) => {
        str += `${item + ' '}`
      })
      this.$emit('structAmount', str)
    }
  }
}
</script>

<style scoped lang="scss">
.editBtnProductTablePart {
  margin-top: 1%;
  padding: 20px;
  border: 1px solid #ccc;
  .tableTitle {
    margin: 0;
  }
}
</style>
